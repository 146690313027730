.shop-slider {
  padding-top: 67px;
  padding-bottom: 54px;
}
.shop-slider .shop-slider__slider-arrow {
  top: 50%;
  width: 20px;
  height: 60px;
  margin-top: 0;
  transform: translateY(-50%);
}
.shop-slider .shop-slider__slider-arrow svg {
  width: 23px;
  height: 60px;
}
.shop-slider .shop-slider__slider-arrow svg path {
  stroke: #ffffff;
}
.shop-slider .shop-slider__slider-arrow.shop-slider__slider-arrow--prev {
  left: 105px;
}
.shop-slider .shop-slider__slider-arrow.shop-slider__slider-arrow--next {
  right: 105px;
}
.shop-slider .shop-slider__slider-arrow::after {
  content: "";
}
.shop-slider .shop-slider__slider-pagination.swiper-pagination-bullets {
  bottom: 56px;
}
.shop-slider .shop-slider__slider-pagination .swiper-pagination-bullet {
  display: inline-block;
  opacity: 1;
  width: 12px;
  height: 12px;
  margin: 0 6px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  background-color: transparent;
}
.shop-slider .shop-slider__slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #ffffff;
}
.shop-slider:not(.shop-slider--init-swiper) .shop-slider__slider-arrow {
  display: none;
}
.shop-slider:not(.shop-slider--init-swiper) .shop-slider__slider-pagination {
  display: none;
}
.shop-slider .shop-slider__heading {
  margin-bottom: 43px;
  font-size: 40px;
  text-align: center;
}
.shop-slider .shop-slider__image-container {
  position: relative;
  padding-top: 52.08%;
}
.shop-slider .shop-slider__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: var(--image-position-desktop);
}
.shop-slider .shop-slider__thumbnail-slider-wrapper {
  margin-top: 55px;
}
.shop-slider .shop-slider__info {
  max-width: 556px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Acumin Pro", sans-serif;
  font-weight: 400;
  text-align: center;
}
.shop-slider .shop-slider__info a {
  letter-spacing: 0.01em;
  color: #666666 !important;
  transition: all 0.3s ease;
}
.shop-slider .shop-slider__info a:focus-visible {
  outline: 1px solid #666666;
}
.shop-slider .shop-slider__info a span {
  color: #666666 !important;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .shop-slider .shop-slider__info a:hover {
    opacity: 0.6;
  }
}
.shop-slider .shop-slider__info-heading {
  font-size: 16px;
  line-height: 1.22;
  letter-spacing: 0.08em;
}
.shop-slider .shop-slider__info-text {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: #2b2b2b;
}
@media (max-width: 1199px) {
  .shop-slider {
    padding-top: 50px;
    padding-bottom: 45px;
  }
  .shop-slider .shop-slider__slider-arrow.shop-slider__slider-arrow--prev {
    left: 70px;
  }
  .shop-slider .shop-slider__slider-arrow.shop-slider__slider-arrow--next {
    right: 70px;
  }
}
@media (max-width: 991px) {
  .shop-slider {
    padding-top: 43px;
    padding-bottom: 36px;
  }
  .shop-slider .shop-slider__slider-arrow.shop-slider__slider-arrow--prev {
    left: 50px;
  }
  .shop-slider .shop-slider__slider-arrow.shop-slider__slider-arrow--next {
    right: 50px;
  }
  .shop-slider .shop-slider__slider-pagination.swiper-pagination-bullets {
    bottom: 45px;
  }
  .shop-slider .shop-slider__info {
    max-width: 450px;
  }
}
@media (max-width: 767px) {
  .shop-slider {
    padding-top: 22px;
    padding-bottom: 15px;
  }
  .shop-slider .shop-slider__heading {
    margin-bottom: 18px;
    font-size: 30px;
  }
  .shop-slider .shop-slider__image-container {
    padding-top: 66.65%;
  }
  .shop-slider .shop-slider__image {
    object-position: var(--image-position-mobile);
  }
  .shop-slider .shop-slider__slider-arrow {
    display: none;
  }
  .shop-slider .shop-slider__slider-pagination.swiper-pagination-bullets {
    bottom: 17px;
  }
  .shop-slider .shop-slider__slider-pagination .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0 5px;
  }
  .shop-slider .shop-slider__thumbnail-slider-wrapper {
    margin-top: 14px;
  }
  .shop-slider .shop-slider__info {
    max-width: 330px;
  }
  .shop-slider .shop-slider__info-heading {
    font-size: 14px;
    line-height: 1.43;
  }
  .shop-slider .shop-slider__info-text {
    margin-top: 11px;
    font-size: 12px;
    line-height: 1.33;
  }
}
@media (max-width: 380px) {
  .shop-slider {
    padding-bottom: 0;
  }
}